.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  height: 100vh;
}

.App > div + div {
  margin-top: 2rem;
}

.connect-wallet,
.wallet-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.wallet-info > div > p:first-of-type {
  overflow-x: auto;
}

button {
  padding: 1rem;
  border-radius: 12px;
  outline: none;
  background-color: #f1f3f5;
  border-radius: 0.25rem;
  border: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.button-disconnect {
  background-color: #ffc9c9;
}

button:hover {
  opacity: 0.8;
}

button:active {
  opacity: 0.6;
}

button + button {
  margin-top: 1rem;
}

.text-error {
  color: #ff6b6b;
}

.ui.vertical.tabular.menu {
  border-right: 0px !important;
}

.ui.vertical.tabular.menu {
  border-color: #fff !important;
}